import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/client",
    name: "client",
    component: () => import("../views/ClientView.vue"),
  },
  {
    path: "/appointments",
    name: "appointments",
    component: () => import("../views/AppointmentView.vue"),
  },
  {
    path: "/appointments/:id",
    name: "appointments.mine",
    props: true,
    component: () => import("../views/AppointmentMineView.vue"),
  },
  {
    path: "/medics",
    name: "medics",
    component: () => import("../views/MedicView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
  },
  {
    path: "/register/patients",
    name: "register.patients",
    component: () => import("../views/register_patients.vue"),
  },
  {
    path: "/register/providers",
    name: "register.providers",
    component: () => import("../views/register_providers.vue"),
  },
  {
    path: "/lab",
    name: "lab",
    component: () => import("../views/LabView.vue"),
  },
  {
    path: "/pharmacy",
    name: "pharmacy",
    component: () => import("../views/PharmacyView.vue"),
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("../views/BlogsView.vue"),
    children: [
      {
        path: "",
        name: "blog.start",
        component: () => import("../views/BlogStart.vue"),
      },
      {
        path: "/:id/:title",
        name: "blog.detail",
        props: true,
        component: () => import("../views/BlogDetail.vue"),
      },
    ],
  },
  {
    path: "/how-it-works",
    name: "our_works",
    component: () => import("../views/HowItWorksView.vue"),
  },
  // search routes starts
  {
    path: "/search",
    name: "searching",
    component: () => import("../views/SearchingView.vue"),
  },
  {
    path: "/search/:name",
    name: "search_query",
    props: true,
    component: () => import("../views/SearchQueryView.vue"),
  },
  {
    path: "/search/:query/:id",
    name: "search_category",
    // props: (route) => ({ query: route.query.q }),
    props: true,
    component: () => import("../views/SearchCategoryView.vue"),
  },
  {
    path: "/stype/:name",
    name: "search_type",
    props: true,
    component: () => import("../views/SearchTypeView.vue"),
  },
  // search routes ends
  {
    path: "/ai/:q",
    name: "search_ai",
    props: true,
    component: () => import("../views/ChatAI.vue"),
  },
  {
    path: "/u/:username",
    name: "my_profile",
    props: true,
    component: () => import("../views/MyProfileView.vue"),
  },
  {
    path: "/rating/:id",
    name: "my_rating",
    props: true,
    component: () => import("../views/MyRatings.vue"),
  },
  {
    path: "/tos",
    name: "tos",
    component: () => import("../views/TOSView.vue"),
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/PrivacyView.vue"),
  },
  {
    path: "/practice",
    name: "practice",
    component: () => import("../views/PracticeView.vue"),
  },
  {
    path: "/team",
    name: "team",
    component: () => import("../views/TeamView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
  },
  {
    path: "/testimonials",
    name: "testimonials",
    component: () => import("../views/TestimonialView.vue"),
  },
  {
    path: "/testimony/:id",
    name: "mytestimonial",
    props: true,
    component: () => import("../views/TestimonyView.vue"),
  },
  {
    path: "/cart",
    name: "cart_base",
    component: () => import("../views/cart/cartView.vue"),
    children: [
      {
        path: "items",
        name: "cart.items",
        component: () => import("../views/cart/MyCart.vue"),
      },
      {
        path: "request/:id",
        name: "cart.request",
        props: true,
        component: () => import("../views/cart/cart_request.vue"),
      },
    ],
    beforeEnter: (to, from, next) => {
      try {
        let active = localStorage.getItem("token");
        // console.log("active:", active);
        if (active !== null) {
          next();
        } else {
          next("/");
        }
      } catch (error) {
        next("/");
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
