import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Vuelidate from "vuelidate";
import moment from "moment";
import VueQRCodeComponent from "vue-qrcode-component";
import GAuth from "vue-google-oauth2";
import VueMeta from "vue-meta";

// css
require("./assets/fonts/Open_Sans/style.css");
require("./assets/css/styles.css");

Vue.use(VueMeta);
Vue.use(GAuth, {
  clientId:
    "404206959532-81gn23n1ebs86dhcu82et1ohu9lcr37l.apps.googleusercontent.com",
  scope: "email",
  prompt: "consent",
  fetch_basic_profile: false,
});

Vue.component("qr-code", VueQRCodeComponent);
Vue.config.productionTip = false;
Vue.use(Vuelidate);

// filters
Vue.filter("capitalize", function (value) {
  if (value) {
    let val = value.toString().trim();
    let arr = val.split(" ");
    let upper = arr.map((element) => {
      return element.charAt(0).toUpperCase() + element.slice(1);
    });
    let arrString = upper.join(" ");
    return arrString;
  }
});

Vue.filter("myTime", function (value) {
  if (value) {
    return moment(value, "HH:mm").format("h:mm:ss a");
  }
});
Vue.filter("myDate", function (value) {
  if (value) {
    return moment(value).format("Do MMM, YYYY");
  }
});

Vue.config.ignoredElements = ["webcms"];
new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
